import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeReportProductTableQueryVariables = Types.Exact<{
  filter: Types.HomeReportFilterInput;
}>;


export type HomeReportProductTableQuery = { readonly reportHomeTop5Products: { readonly __typename?: 'HomeTop5ProductsReportView', readonly data: ReadonlyArray<{ readonly __typename?: 'HomeTop5ProductView', readonly currency: Types.ReportSupportedCurrency, readonly grossSales: string, readonly image?: string | null, readonly orderCount: number, readonly productId?: string | null, readonly productName?: string | null }> } };


export const HomeReportProductTableDocument = gql`
    query HomeReportProductTable($filter: HomeReportFilterInput!) {
  reportHomeTop5Products(filter: $filter) {
    data {
      currency
      grossSales
      image
      orderCount
      productId
      productName
    }
  }
}
    `;

/**
 * __useHomeReportProductTableQuery__
 *
 * To run a query within a React component, call `useHomeReportProductTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeReportProductTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeReportProductTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeReportProductTableQuery(baseOptions: Apollo.QueryHookOptions<HomeReportProductTableQuery, HomeReportProductTableQueryVariables> & ({ variables: HomeReportProductTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeReportProductTableQuery, HomeReportProductTableQueryVariables>(HomeReportProductTableDocument, options);
      }
export function useHomeReportProductTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeReportProductTableQuery, HomeReportProductTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeReportProductTableQuery, HomeReportProductTableQueryVariables>(HomeReportProductTableDocument, options);
        }
export function useHomeReportProductTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomeReportProductTableQuery, HomeReportProductTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeReportProductTableQuery, HomeReportProductTableQueryVariables>(HomeReportProductTableDocument, options);
        }
export type HomeReportProductTableQueryHookResult = ReturnType<typeof useHomeReportProductTableQuery>;
export type HomeReportProductTableLazyQueryHookResult = ReturnType<typeof useHomeReportProductTableLazyQuery>;
export type HomeReportProductTableSuspenseQueryHookResult = ReturnType<typeof useHomeReportProductTableSuspenseQuery>;
export type HomeReportProductTableQueryResult = Apollo.QueryResult<HomeReportProductTableQuery, HomeReportProductTableQueryVariables>;
export function refetchHomeReportProductTableQuery(variables: HomeReportProductTableQueryVariables) {
      return { query: HomeReportProductTableDocument, variables: variables }
    }