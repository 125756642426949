import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductSetVariantsSelectorTagsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductTagsFilterInput>;
}>;


export type ProductSetVariantsSelectorTagsQuery = { readonly tagsV2: ReadonlyArray<string> };


export const ProductSetVariantsSelectorTagsDocument = gql`
    query ProductSetVariantsSelectorTags($filter: ProductTagsFilterInput) {
  tagsV2(filter: $filter)
}
    `;

/**
 * __useProductSetVariantsSelectorTagsQuery__
 *
 * To run a query within a React component, call `useProductSetVariantsSelectorTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSetVariantsSelectorTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSetVariantsSelectorTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductSetVariantsSelectorTagsQuery(baseOptions?: Apollo.QueryHookOptions<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>(ProductSetVariantsSelectorTagsDocument, options);
      }
export function useProductSetVariantsSelectorTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>(ProductSetVariantsSelectorTagsDocument, options);
        }
export function useProductSetVariantsSelectorTagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>(ProductSetVariantsSelectorTagsDocument, options);
        }
export type ProductSetVariantsSelectorTagsQueryHookResult = ReturnType<typeof useProductSetVariantsSelectorTagsQuery>;
export type ProductSetVariantsSelectorTagsLazyQueryHookResult = ReturnType<typeof useProductSetVariantsSelectorTagsLazyQuery>;
export type ProductSetVariantsSelectorTagsSuspenseQueryHookResult = ReturnType<typeof useProductSetVariantsSelectorTagsSuspenseQuery>;
export type ProductSetVariantsSelectorTagsQueryResult = Apollo.QueryResult<ProductSetVariantsSelectorTagsQuery, ProductSetVariantsSelectorTagsQueryVariables>;
export function refetchProductSetVariantsSelectorTagsQuery(variables?: ProductSetVariantsSelectorTagsQueryVariables) {
      return { query: ProductSetVariantsSelectorTagsDocument, variables: variables }
    }