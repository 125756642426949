import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkspaceDataFragment = { readonly __typename?: 'MdStore', readonly name: string, readonly id: string, readonly account?: { readonly __typename?: 'MdAccount', readonly id: string, readonly name: string, readonly product: Types.MdAccountProduct } | null, readonly crmStore?: { readonly __typename?: 'AnyChatStore', readonly isCrmEnabled: boolean, readonly chats: ReadonlyArray<{ readonly __typename?: 'InstagramStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'LazadaStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'LineStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'MessengerStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'ShopeeStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'TikTokShopStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'WhatsAppStoreChat', readonly type: Types.ChatType }> } | null };

export type InitWorkspaceQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.MdStoreFilterInput>;
  sortBy?: Types.InputMaybe<Types.MdStoreSortByInput>;
}>;


export type InitWorkspaceQuery = { readonly MdStores: { readonly __typename?: 'MdStores', readonly items: ReadonlyArray<{ readonly __typename?: 'MdStore', readonly name: string, readonly id: string, readonly account?: { readonly __typename?: 'MdAccount', readonly id: string, readonly name: string, readonly product: Types.MdAccountProduct } | null, readonly crmStore?: { readonly __typename?: 'AnyChatStore', readonly isCrmEnabled: boolean, readonly chats: ReadonlyArray<{ readonly __typename?: 'InstagramStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'LazadaStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'LineStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'MessengerStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'ShopeeStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'TikTokShopStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'WhatsAppStoreChat', readonly type: Types.ChatType }> } | null }> } };

export type ValidateWorkspaceQueryVariables = Types.Exact<{
  mdStoreId: Types.Scalars['Long']['input'];
}>;


export type ValidateWorkspaceQuery = { readonly MdStore: { readonly __typename?: 'MdStore', readonly name: string, readonly id: string, readonly account?: { readonly __typename?: 'MdAccount', readonly id: string, readonly name: string, readonly product: Types.MdAccountProduct } | null, readonly crmStore?: { readonly __typename?: 'AnyChatStore', readonly isCrmEnabled: boolean, readonly chats: ReadonlyArray<{ readonly __typename?: 'InstagramStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'LazadaStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'LineStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'MessengerStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'ShopeeStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'TikTokShopStoreChat', readonly type: Types.ChatType } | { readonly __typename?: 'WhatsAppStoreChat', readonly type: Types.ChatType }> } | null } };

export const WorkspaceDataFragmentDoc = gql`
    fragment WorkspaceData on MdStore {
  name
  account {
    id
    name
    product
  }
  id
  crmStore {
    isCrmEnabled
    chats {
      type
    }
  }
}
    `;
export const InitWorkspaceDocument = gql`
    query InitWorkspace($pageNumber: Int!, $pageSize: Int!, $filter: MdStoreFilterInput, $sortBy: MdStoreSortByInput) {
  MdStores(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sortBy: $sortBy
  ) {
    items {
      ...WorkspaceData
    }
  }
}
    ${WorkspaceDataFragmentDoc}`;

/**
 * __useInitWorkspaceQuery__
 *
 * To run a query within a React component, call `useInitWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitWorkspaceQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useInitWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<InitWorkspaceQuery, InitWorkspaceQueryVariables> & ({ variables: InitWorkspaceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitWorkspaceQuery, InitWorkspaceQueryVariables>(InitWorkspaceDocument, options);
      }
export function useInitWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitWorkspaceQuery, InitWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitWorkspaceQuery, InitWorkspaceQueryVariables>(InitWorkspaceDocument, options);
        }
export function useInitWorkspaceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InitWorkspaceQuery, InitWorkspaceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InitWorkspaceQuery, InitWorkspaceQueryVariables>(InitWorkspaceDocument, options);
        }
export type InitWorkspaceQueryHookResult = ReturnType<typeof useInitWorkspaceQuery>;
export type InitWorkspaceLazyQueryHookResult = ReturnType<typeof useInitWorkspaceLazyQuery>;
export type InitWorkspaceSuspenseQueryHookResult = ReturnType<typeof useInitWorkspaceSuspenseQuery>;
export type InitWorkspaceQueryResult = Apollo.QueryResult<InitWorkspaceQuery, InitWorkspaceQueryVariables>;
export function refetchInitWorkspaceQuery(variables: InitWorkspaceQueryVariables) {
      return { query: InitWorkspaceDocument, variables: variables }
    }
export const ValidateWorkspaceDocument = gql`
    query ValidateWorkspace($mdStoreId: Long!) {
  MdStore(id: $mdStoreId) {
    ...WorkspaceData
  }
}
    ${WorkspaceDataFragmentDoc}`;

/**
 * __useValidateWorkspaceQuery__
 *
 * To run a query within a React component, call `useValidateWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateWorkspaceQuery({
 *   variables: {
 *      mdStoreId: // value for 'mdStoreId'
 *   },
 * });
 */
export function useValidateWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables> & ({ variables: ValidateWorkspaceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables>(ValidateWorkspaceDocument, options);
      }
export function useValidateWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables>(ValidateWorkspaceDocument, options);
        }
export function useValidateWorkspaceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables>(ValidateWorkspaceDocument, options);
        }
export type ValidateWorkspaceQueryHookResult = ReturnType<typeof useValidateWorkspaceQuery>;
export type ValidateWorkspaceLazyQueryHookResult = ReturnType<typeof useValidateWorkspaceLazyQuery>;
export type ValidateWorkspaceSuspenseQueryHookResult = ReturnType<typeof useValidateWorkspaceSuspenseQuery>;
export type ValidateWorkspaceQueryResult = Apollo.QueryResult<ValidateWorkspaceQuery, ValidateWorkspaceQueryVariables>;
export function refetchValidateWorkspaceQuery(variables: ValidateWorkspaceQueryVariables) {
      return { query: ValidateWorkspaceDocument, variables: variables }
    }