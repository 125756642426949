import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdStoresChannelsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.MdStoreFilterInput>;
  sortBy?: Types.InputMaybe<Types.MdStoreSortByInput>;
}>;


export type MdStoresChannelsQuery = { readonly MdStores: { readonly __typename?: 'MdStores', readonly items: ReadonlyArray<{ readonly __typename?: 'MdStore', readonly id: string, readonly channels: ReadonlyArray<{ readonly __typename?: 'SaleChannel', readonly id: string, readonly type: Types.MarketplaceSaleChannelType, readonly isManualChannel: boolean }> }> } };


export const MdStoresChannelsDocument = gql`
    query MdStoresChannels($pageNumber: Int!, $pageSize: Int!, $filter: MdStoreFilterInput, $sortBy: MdStoreSortByInput) {
  MdStores(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sortBy: $sortBy
  ) {
    items {
      id
      channels {
        id
        type
        isManualChannel
      }
    }
  }
}
    `;

/**
 * __useMdStoresChannelsQuery__
 *
 * To run a query within a React component, call `useMdStoresChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdStoresChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdStoresChannelsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMdStoresChannelsQuery(baseOptions: Apollo.QueryHookOptions<MdStoresChannelsQuery, MdStoresChannelsQueryVariables> & ({ variables: MdStoresChannelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>(MdStoresChannelsDocument, options);
      }
export function useMdStoresChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>(MdStoresChannelsDocument, options);
        }
export function useMdStoresChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>(MdStoresChannelsDocument, options);
        }
export type MdStoresChannelsQueryHookResult = ReturnType<typeof useMdStoresChannelsQuery>;
export type MdStoresChannelsLazyQueryHookResult = ReturnType<typeof useMdStoresChannelsLazyQuery>;
export type MdStoresChannelsSuspenseQueryHookResult = ReturnType<typeof useMdStoresChannelsSuspenseQuery>;
export type MdStoresChannelsQueryResult = Apollo.QueryResult<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>;
export function refetchMdStoresChannelsQuery(variables: MdStoresChannelsQueryVariables) {
      return { query: MdStoresChannelsDocument, variables: variables }
    }