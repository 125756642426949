import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DdiHomeReportSummaryQueryVariables = Types.Exact<{
  input: Types.DdiHomeReportInput;
}>;


export type DdiHomeReportSummaryQuery = { readonly ddiHomeReportSummary: { readonly __typename?: 'DdiHomeReportSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly grossSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly orders: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly netSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const DdiHomeReportSummaryDocument = gql`
    query DDIHomeReportSummary($input: DdiHomeReportInput!) {
  ddiHomeReportSummary(input: $input) {
    grossSales {
      diff
      diffPercentage
      value
    }
    orders {
      diff
      diffPercentage
      value
    }
    netSales {
      diff
      diffPercentage
      value
    }
    currency
  }
}
    `;

/**
 * __useDdiHomeReportSummaryQuery__
 *
 * To run a query within a React component, call `useDdiHomeReportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDdiHomeReportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDdiHomeReportSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDdiHomeReportSummaryQuery(baseOptions: Apollo.QueryHookOptions<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables> & ({ variables: DdiHomeReportSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables>(DdiHomeReportSummaryDocument, options);
      }
export function useDdiHomeReportSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables>(DdiHomeReportSummaryDocument, options);
        }
export function useDdiHomeReportSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables>(DdiHomeReportSummaryDocument, options);
        }
export type DdiHomeReportSummaryQueryHookResult = ReturnType<typeof useDdiHomeReportSummaryQuery>;
export type DdiHomeReportSummaryLazyQueryHookResult = ReturnType<typeof useDdiHomeReportSummaryLazyQuery>;
export type DdiHomeReportSummarySuspenseQueryHookResult = ReturnType<typeof useDdiHomeReportSummarySuspenseQuery>;
export type DdiHomeReportSummaryQueryResult = Apollo.QueryResult<DdiHomeReportSummaryQuery, DdiHomeReportSummaryQueryVariables>;
export function refetchDdiHomeReportSummaryQuery(variables: DdiHomeReportSummaryQueryVariables) {
      return { query: DdiHomeReportSummaryDocument, variables: variables }
    }