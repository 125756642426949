
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AutoReplyInfo": [
      "LiveCommentAutoReplyInfo"
    ],
    "AutomationFlowContent": [
      "AutomationTemplateContent",
      "AutomationTextContent"
    ],
    "AutomationFlowTrigger": [
      "AnyContent",
      "ContainsKeyword"
    ],
    "ChatInfo": [
      "InstagramInfo",
      "LazadaInfo",
      "LineInfo",
      "MessengerInfo",
      "ShopeeInfo",
      "TikTokShopInfo",
      "WhatsAppInfo"
    ],
    "ConnectLazadaResponse": [
      "ConnectLazadaResponseSuccess",
      "UnknownErrorConnectLazadaError"
    ],
    "ConnectShopeeChatResponse": [
      "ConnectShopeeChatContinueSelectShopResponse",
      "ConnectShopeeChatSuccessResponse"
    ],
    "ConnectToFacebookResponseV2": [
      "AccountAlreadyHaveConnection",
      "ConnectToFacebookResponseSuccess",
      "MessengerNotSupportChatPlugInWithAnonymousUserFacebookError",
      "NoInstagramAccountConnectToFacebookError",
      "PageHaveConnectedToOtherStore",
      "UnknownErrorConnectToFacebookError"
    ],
    "ConnectToWhatsAppResponse": [
      "ConnectToWhatsAppAccountAlreadyHaveConnection",
      "ConnectToWhatsAppResponseSuccess",
      "UnknownErrorConnectToWhatsAppError"
    ],
    "CourierDetails": [
      "DHLAccountDetails",
      "FedExAccountDetails",
      "JapanPostAccountDetails",
      "UPSAccountDetails"
    ],
    "CrossBorderServiceCode": [
      "DHLServiceCodeWrapper",
      "FedExServiceCodeWrapper",
      "JapanPostServiceCodeWrapper",
      "UPSServiceCodeWrapper"
    ],
    "CustomerActivity": [
      "AddCustomerTagsActivity",
      "CustomerCreatedActivity",
      "InfluencerJoinedCampaignActivity",
      "InfluencerPublishedPostActivity",
      "InfluencerSubmittedPostActivity",
      "InquiryReceivedActivity",
      "InquiryResolvedActivity",
      "InquiryUnresolvedActivity",
      "MessengerOptInAcceptedActivity",
      "MessengerOptInRequestSentActivity",
      "MessengerOptedOutActivity",
      "NotedActivity",
      "ProfileUpdatedActivity",
      "RemoveCustomerTagsActivity",
      "ShopeeOrderedActivity",
      "ShopifyOrderedActivity",
      "WhatsAppOptInAcceptedActivity",
      "WhatsAppOptedInRequestActivity",
      "WhatsAppOptedOutActivity"
    ],
    "CustomerTagDeleteResponse": [
      "CustomerTagDeleteFailure",
      "CustomerTagDeleteSuccess"
    ],
    "DeliveryTiming": [
      "Immediately",
      "Schedule"
    ],
    "InstagramChatEvent": [
      "InstagramMessageEvent"
    ],
    "InstagramMessage": [
      "InstagramBotAutoReplyMessage",
      "InstagramBotAutoReplyMessageDelete",
      "InstagramBotMessage",
      "InstagramBotMessageDeleted",
      "InstagramUserMessage",
      "InstagramUserMessageDeleted"
    ],
    "InstagramMessageContent": [
      "AutoReplyContent",
      "ImageMessageContent",
      "StoryMentionContent",
      "TextLiveCommentContent",
      "TextMessageContent",
      "UnsupportedContent",
      "VideoMessageContent"
    ],
    "InstagramReaction": [
      "BotReaction",
      "UserReaction"
    ],
    "LazadaChatEvent": [
      "LazadaMessageEvent"
    ],
    "LazadaMessage": [
      "LazadaStoreCSMessage",
      "LazadaUserMessage"
    ],
    "LazadaMessageContent": [
      "LazadaEmojiMessageContent",
      "LazadaImageMessageContent",
      "LazadaItemMessageContent",
      "LazadaOrderMessageContent",
      "LazadaTextMessageContent",
      "LazadaUnsupportedMessageContent",
      "LazadaVideoMessageContent"
    ],
    "LineCardButtonAction": [
      "LinkedPostbackAction",
      "UrlAction"
    ],
    "LineChatEvent": [
      "LineAIMessageFailedToSendEvent",
      "LineBotGreetingMessageEvent",
      "LineBotImageMessageEvent",
      "LineBotTextMessageEvent",
      "LineInfluencerJoinedCampaignEvent",
      "LineInquiryEvent",
      "LineInquiryFinishEvent",
      "LineMessageTemplateEvent",
      "LineStateUpdateEvent",
      "LineSystemFoundFollowerEvent",
      "LineUserAudioMessageEvent",
      "LineUserFileMessageEvent",
      "LineUserFollowedEvent",
      "LineUserImageMessageEvent",
      "LineUserLocationMessageEvent",
      "LineUserPostbackEvent",
      "LineUserStickerMessageEvent",
      "LineUserTextMessageEvent",
      "LineUserUnFollowedEvent",
      "LineUserVideoMessageEvent"
    ],
    "LineMessage": [
      "ButtonMessage",
      "ImageMessage",
      "ImageVideoMessage",
      "PlainTextMessage",
      "RichMessage"
    ],
    "LineRichMenuAction": [
      "LineRichMenuMessageAction",
      "LineRichMenuPostbackAction",
      "LineRichMenuUriAction"
    ],
    "LineRichMenuSubmitResponse": [
      "LineRichMenuSubmitFailExistPermanent",
      "LineRichMenuSubmitFailOverlap",
      "LineRichMenuSubmitSuccess"
    ],
    "LineRichMenuUpdateResponse": [
      "LineRichMenuUpdateFailExistPermanent",
      "LineRichMenuUpdateFailOverlap",
      "LineRichMenuUpdateSuccess"
    ],
    "LineStickerData": [
      "AnimatedSticker",
      "OtherSticker",
      "StaticSticker"
    ],
    "ListMeta": [
      "MdAccounts",
      "MdStores",
      "MdUsers"
    ],
    "Media": [
      "Image",
      "Video"
    ],
    "MessageAuthor": [
      "AIAuthor",
      "BasicUser"
    ],
    "MessengerBroadcastRecipient": [
      "All",
      "CustomerTagName"
    ],
    "MessengerChatEvent": [
      "MessengerMessageEvent"
    ],
    "MessengerContent": [
      "MessengerTemplateContent",
      "MessengerTextContent"
    ],
    "MessengerMessage": [
      "MessengerBotAutoMessage",
      "MessengerBotAutoMessageDeleted",
      "MessengerBotMessage",
      "MessengerBotMessageDeleted",
      "MessengerUserMessage",
      "MessengerUserMessageDeleted"
    ],
    "MessengerMessageContent": [
      "MessengerImageCommentContent",
      "MessengerImageMessageContent",
      "MessengerOptInContent",
      "MessengerOptOutContent",
      "MessengerPostOnPageContent",
      "MessengerRequestOptInContent",
      "MessengerResumeOptInContent",
      "MessengerTextCommentContent",
      "MessengerTextMessageContent",
      "MessengerUnsupportedContent",
      "MessengerVideoMessageContent"
    ],
    "MessengerReaction": [
      "MessengerBotReaction",
      "MessengerUserReaction"
    ],
    "MessengerReactionSign": [
      "MessengerEmoji",
      "MessengerSign"
    ],
    "MessengerReplyTo": [
      "MessengerReplyToMessage"
    ],
    "MetaTokenStatus": [
      "InvalidToken",
      "TokenMissingScope",
      "Working"
    ],
    "PackageType": [
      "DHLPackageWrapper",
      "FedExPackageWrapper"
    ],
    "Paginated": [
      "AnylogiOrdersPaginated",
      "AnylogiUsersPaginated",
      "CourierAccountDetailsListResponse",
      "InboundsPaginated",
      "InventoryPaginated",
      "PickupDetailsListResponse",
      "SkuPaginated",
      "SuppliersPaginated",
      "WarehousesPaginated",
      "getCourierSettingsListResponse",
      "shipperAddressesListResponse"
    ],
    "PickupServiceCode": [
      "DHLPickupServiceCodeWrapper",
      "FedExPickupServiceCodeWrapper",
      "UPSPickupServiceCodeWrapper"
    ],
    "ReactionSign": [
      "Emoji",
      "InstagramSign"
    ],
    "Recipient": [
      "All",
      "Condition",
      "CustomerTagNames",
      "InfluencerCondition"
    ],
    "RefreshFacebookTokenResponse": [
      "NoInstagramAccountRefreshTokenError",
      "RefreshFacebookTokenResponseSuccess",
      "UnknownErrorRefreshTokenError",
      "WrongPageSelectedRefreshTokenError"
    ],
    "ReplyTo": [
      "ReplyToMessage",
      "ReplyToStory"
    ],
    "SaleChannelData": [
      "AmazonSaleChannelData",
      "EmptySaleChannelData",
      "LazadaSaleChannelData",
      "Qoo10SaleChannelData",
      "RakutenSaleChannelData",
      "ShopeeSaleChannelData",
      "ShopifySaleChannelData",
      "TikTokSaleChannelData",
      "TokopediaSaleChannelData",
      "YahooSaleChannelData"
    ],
    "SaleChannelSpecificData": [
      "LazadaSpecificData",
      "ManualsSpecificData",
      "ShopifySpecificData"
    ],
    "SendMessengerBroadcastResponse": [
      "CompleteSendingMessengerBroadcastSuccess",
      "FailToStartSendingMessengerBroadcastTooManyUnavailableCustomer",
      "StartSendingMessengerBroadcastSuccess"
    ],
    "ShopeeChatEvent": [
      "ShopeeMessageEvent"
    ],
    "ShopeeMessage": [
      "ShopeeStoreCSMessage",
      "ShopeeUserMessage"
    ],
    "ShopeeMessageContent": [
      "ShopeeImageMessageContent",
      "ShopeeItemMessageContent",
      "ShopeeOrderMessageContent",
      "ShopeeStickerMessageContent",
      "ShopeeTextMessageContent",
      "ShopeeVideoMessageContent"
    ],
    "ShopeeMessageSource": [
      "ItemSource",
      "OrderSource"
    ],
    "SpecificChatPlatformDetail": [
      "InstagramChatPlatformDetail",
      "LazadaChatPlatformDetail",
      "LineChatPlatformDetail",
      "MessengerChatPlatformDetail",
      "ShopeeChatPlatformDetail",
      "TikTokShopChatPlatformDetail",
      "WhatsAppChatPlatformDetail"
    ],
    "StoreChat": [
      "InstagramStoreChat",
      "LazadaStoreChat",
      "LineStoreChat",
      "MessengerStoreChat",
      "ShopeeStoreChat",
      "TikTokShopStoreChat",
      "WhatsAppStoreChat"
    ],
    "WhatsAppBroadcastActionButton": [
      "WhatsAppBroadcastUrlActionButton"
    ],
    "WhatsAppBroadcastContent": [
      "WhatsAppBroadcastCardContent",
      "WhatsAppBroadcastTextContent"
    ],
    "WhatsAppBroadcastRecipient": [
      "All",
      "WhatsAppRecipientCustomerTagName"
    ],
    "WhatsAppChatEvent": [
      "WhatsAppMessageEvent"
    ],
    "WhatsAppMessage": [
      "WhatsAppAutoReplyMessage",
      "WhatsAppStoreCSMessage",
      "WhatsAppUserMessage"
    ],
    "WhatsAppMessageAutoContent": [
      "WhatsAppOptInRequestMessageContent"
    ],
    "WhatsAppMessageReceivedContent": [
      "WhatsAppImageMessageContent",
      "WhatsAppOptInMessageContent",
      "WhatsAppOptOutMessageContent",
      "WhatsAppTextMessageContent",
      "WhatsAppUnsupportedContent",
      "WhatsAppVideoMessageContent"
    ],
    "WhatsAppMessageSentContent": [
      "WhatsAppImageMessageContent",
      "WhatsAppTextMessageContent",
      "WhatsAppVideoMessageContent"
    ],
    "getInventoriesCsvResponse": [
      "Document",
      "Job"
    ]
  }
};
      export default result;
    