import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeReportChartQueryVariables = Types.Exact<{
  filter: Types.SaleChannelReportTableFilterInput;
}>;


export type HomeReportChartQuery = { readonly saleChannelReportChart: { readonly __typename?: 'SaleChannelReportChartPayload', readonly channels: ReadonlyArray<Types.SaleChannelType>, readonly currency: Types.ReportSupportedCurrency, readonly totalItems: number, readonly list: ReadonlyArray<{ readonly __typename?: 'SaleChannelReportChartData', readonly advertisingCost: string, readonly advertisingRevenue: string, readonly avgOrderValues: string, readonly channel?: Types.SaleChannelType | null, readonly commissionFee: string, readonly cvr?: string | null, readonly dailySales?: string | null, readonly day?: any | null, readonly discountRate: string, readonly discounts: string, readonly dow?: number | null, readonly grossProfit: string, readonly grossProfitRate: string, readonly grossSales: string, readonly netSales: string, readonly totalSales: string, readonly cancelAndOrRefund: string, readonly logisticsCost: string, readonly marginalProfit: string, readonly marginalProfitRate: string, readonly masterDataStoreId?: string | null, readonly merchandiseCost: string, readonly orders: string, readonly otherCost: string, readonly roas: string, readonly sessions: string, readonly shippingFee: string, readonly storeName?: string | null, readonly yearMonth?: any | null, readonly yearWeek?: any | null, readonly marketplaceCalendarEvents?: ReadonlyArray<{ readonly __typename?: 'MarketplaceCalendarEventPayload', readonly endDate: string, readonly id: string, readonly masterDataStoreId?: string | null, readonly saleChannelCountry?: string | null, readonly saleChannelType: Types.SaleChannelType, readonly startDate: string, readonly title: string, readonly target?: { readonly __typename?: 'MarketplaceCalendarEventTargetPayload', readonly currency: Types.ReportSupportedCurrency, readonly revenue?: string | null } | null }> | null }>, readonly stores: ReadonlyArray<{ readonly __typename?: 'SaleChannelReportChartStore', readonly id: string, readonly name: string }> } };


export const HomeReportChartDocument = gql`
    query HomeReportChart($filter: SaleChannelReportTableFilterInput!) {
  saleChannelReportChart(filter: $filter) {
    channels
    currency
    list {
      advertisingCost
      advertisingRevenue
      avgOrderValues
      channel
      commissionFee
      cvr
      dailySales
      day
      discountRate
      discounts
      dow
      grossProfit
      grossProfitRate
      grossSales
      netSales
      totalSales
      cancelAndOrRefund
      logisticsCost
      marginalProfit
      marginalProfitRate
      marketplaceCalendarEvents {
        endDate
        id
        masterDataStoreId
        saleChannelCountry
        saleChannelType
        startDate
        target {
          currency
          revenue
        }
        title
      }
      masterDataStoreId
      merchandiseCost
      orders
      otherCost
      roas
      sessions
      shippingFee
      storeName
      yearMonth
      yearWeek
    }
    stores {
      id
      name
    }
    totalItems
  }
}
    `;

/**
 * __useHomeReportChartQuery__
 *
 * To run a query within a React component, call `useHomeReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeReportChartQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeReportChartQuery(baseOptions: Apollo.QueryHookOptions<HomeReportChartQuery, HomeReportChartQueryVariables> & ({ variables: HomeReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeReportChartQuery, HomeReportChartQueryVariables>(HomeReportChartDocument, options);
      }
export function useHomeReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeReportChartQuery, HomeReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeReportChartQuery, HomeReportChartQueryVariables>(HomeReportChartDocument, options);
        }
export function useHomeReportChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomeReportChartQuery, HomeReportChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeReportChartQuery, HomeReportChartQueryVariables>(HomeReportChartDocument, options);
        }
export type HomeReportChartQueryHookResult = ReturnType<typeof useHomeReportChartQuery>;
export type HomeReportChartLazyQueryHookResult = ReturnType<typeof useHomeReportChartLazyQuery>;
export type HomeReportChartSuspenseQueryHookResult = ReturnType<typeof useHomeReportChartSuspenseQuery>;
export type HomeReportChartQueryResult = Apollo.QueryResult<HomeReportChartQuery, HomeReportChartQueryVariables>;
export function refetchHomeReportChartQuery(variables: HomeReportChartQueryVariables) {
      return { query: HomeReportChartDocument, variables: variables }
    }