import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeReportPieChartQueryVariables = Types.Exact<{
  filter: Types.HomeReportFilterInput;
}>;


export type HomeReportPieChartQuery = { readonly reportHomeSaleChannelRevenue: { readonly __typename?: 'HomeSaleChannelRevenueReportView', readonly currency: Types.ReportSupportedCurrency, readonly data: ReadonlyArray<{ readonly __typename?: 'HomeReportSaleChannelRevenuePayload', readonly grossSales: string, readonly masterDataStoreId?: string | null, readonly saleChannelType?: Types.SaleChannelType | null, readonly storeName?: string | null }> } };


export const HomeReportPieChartDocument = gql`
    query HomeReportPieChart($filter: HomeReportFilterInput!) {
  reportHomeSaleChannelRevenue(filter: $filter) {
    currency
    data {
      grossSales
      masterDataStoreId
      saleChannelType
      storeName
    }
  }
}
    `;

/**
 * __useHomeReportPieChartQuery__
 *
 * To run a query within a React component, call `useHomeReportPieChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeReportPieChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeReportPieChartQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeReportPieChartQuery(baseOptions: Apollo.QueryHookOptions<HomeReportPieChartQuery, HomeReportPieChartQueryVariables> & ({ variables: HomeReportPieChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeReportPieChartQuery, HomeReportPieChartQueryVariables>(HomeReportPieChartDocument, options);
      }
export function useHomeReportPieChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeReportPieChartQuery, HomeReportPieChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeReportPieChartQuery, HomeReportPieChartQueryVariables>(HomeReportPieChartDocument, options);
        }
export function useHomeReportPieChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomeReportPieChartQuery, HomeReportPieChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeReportPieChartQuery, HomeReportPieChartQueryVariables>(HomeReportPieChartDocument, options);
        }
export type HomeReportPieChartQueryHookResult = ReturnType<typeof useHomeReportPieChartQuery>;
export type HomeReportPieChartLazyQueryHookResult = ReturnType<typeof useHomeReportPieChartLazyQuery>;
export type HomeReportPieChartSuspenseQueryHookResult = ReturnType<typeof useHomeReportPieChartSuspenseQuery>;
export type HomeReportPieChartQueryResult = Apollo.QueryResult<HomeReportPieChartQuery, HomeReportPieChartQueryVariables>;
export function refetchHomeReportPieChartQuery(variables: HomeReportPieChartQueryVariables) {
      return { query: HomeReportPieChartDocument, variables: variables }
    }