import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStoresLogisticConnectionFlagQueryVariables = Types.Exact<{
  mdStoreIds?: Types.InputMaybe<ReadonlyArray<Types.Scalars['Long']['input']> | Types.Scalars['Long']['input']>;
}>;


export type GetStoresLogisticConnectionFlagQuery = { readonly GetHighestLogisticConnectionType?: Types.LogisticFeatureFlag | null };


export const GetStoresLogisticConnectionFlagDocument = gql`
    query GetStoresLogisticConnectionFlag($mdStoreIds: [Long!]) {
  GetHighestLogisticConnectionType(MdStoreIds: $mdStoreIds)
}
    `;

/**
 * __useGetStoresLogisticConnectionFlagQuery__
 *
 * To run a query within a React component, call `useGetStoresLogisticConnectionFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresLogisticConnectionFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresLogisticConnectionFlagQuery({
 *   variables: {
 *      mdStoreIds: // value for 'mdStoreIds'
 *   },
 * });
 */
export function useGetStoresLogisticConnectionFlagQuery(baseOptions?: Apollo.QueryHookOptions<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>(GetStoresLogisticConnectionFlagDocument, options);
      }
export function useGetStoresLogisticConnectionFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>(GetStoresLogisticConnectionFlagDocument, options);
        }
export function useGetStoresLogisticConnectionFlagSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>(GetStoresLogisticConnectionFlagDocument, options);
        }
export type GetStoresLogisticConnectionFlagQueryHookResult = ReturnType<typeof useGetStoresLogisticConnectionFlagQuery>;
export type GetStoresLogisticConnectionFlagLazyQueryHookResult = ReturnType<typeof useGetStoresLogisticConnectionFlagLazyQuery>;
export type GetStoresLogisticConnectionFlagSuspenseQueryHookResult = ReturnType<typeof useGetStoresLogisticConnectionFlagSuspenseQuery>;
export type GetStoresLogisticConnectionFlagQueryResult = Apollo.QueryResult<GetStoresLogisticConnectionFlagQuery, GetStoresLogisticConnectionFlagQueryVariables>;
export function refetchGetStoresLogisticConnectionFlagQuery(variables?: GetStoresLogisticConnectionFlagQueryVariables) {
      return { query: GetStoresLogisticConnectionFlagDocument, variables: variables }
    }