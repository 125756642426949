import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantValuesSelectQueryVariables = Types.Exact<{
  input: Types.VariantValuesInput;
}>;


export type ProductVariantValuesSelectQuery = { readonly variantValues: { readonly __typename?: 'VariantValuesPayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'VariantValuesPayload', readonly name: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number } } };


export const ProductVariantValuesSelectDocument = gql`
    query ProductVariantValuesSelect($input: VariantValuesInput!) {
  variantValues(input: $input) {
    items {
      name
    }
    meta {
      length
      limit
      start
      total
    }
  }
}
    `;

/**
 * __useProductVariantValuesSelectQuery__
 *
 * To run a query within a React component, call `useProductVariantValuesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantValuesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantValuesSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductVariantValuesSelectQuery(baseOptions: Apollo.QueryHookOptions<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables> & ({ variables: ProductVariantValuesSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables>(ProductVariantValuesSelectDocument, options);
      }
export function useProductVariantValuesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables>(ProductVariantValuesSelectDocument, options);
        }
export function useProductVariantValuesSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables>(ProductVariantValuesSelectDocument, options);
        }
export type ProductVariantValuesSelectQueryHookResult = ReturnType<typeof useProductVariantValuesSelectQuery>;
export type ProductVariantValuesSelectLazyQueryHookResult = ReturnType<typeof useProductVariantValuesSelectLazyQuery>;
export type ProductVariantValuesSelectSuspenseQueryHookResult = ReturnType<typeof useProductVariantValuesSelectSuspenseQuery>;
export type ProductVariantValuesSelectQueryResult = Apollo.QueryResult<ProductVariantValuesSelectQuery, ProductVariantValuesSelectQueryVariables>;
export function refetchProductVariantValuesSelectQuery(variables: ProductVariantValuesSelectQueryVariables) {
      return { query: ProductVariantValuesSelectDocument, variables: variables }
    }