import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckOnboardingQueryVariables = Types.Exact<{
  storeId: Types.Scalars['Long']['input'];
}>;


export type CheckOnboardingQuery = { readonly onboarding: { readonly __typename?: 'Onboarding', readonly hasShopifyChannel: boolean, readonly hasRegisteredOrder: boolean, readonly hasInputUnitCost: boolean, readonly hasConnectedSaleChannel: boolean, readonly hasConnectedDatasourceChannel: boolean, readonly hasSaleChannelCostIndex: boolean, readonly hasAnalyticsReport: boolean, readonly hasAdvertisingDatasource: boolean, readonly inactiveSaleChannelCount: number } };


export const CheckOnboardingDocument = gql`
    query CheckOnboarding($storeId: Long!) {
  onboarding(storeId: $storeId) {
    hasShopifyChannel
    hasRegisteredOrder
    hasInputUnitCost
    hasConnectedSaleChannel
    hasConnectedDatasourceChannel
    hasSaleChannelCostIndex
    hasAnalyticsReport
    hasAdvertisingDatasource
    inactiveSaleChannelCount
  }
}
    `;

/**
 * __useCheckOnboardingQuery__
 *
 * To run a query within a React component, call `useCheckOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOnboardingQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCheckOnboardingQuery(baseOptions: Apollo.QueryHookOptions<CheckOnboardingQuery, CheckOnboardingQueryVariables> & ({ variables: CheckOnboardingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckOnboardingQuery, CheckOnboardingQueryVariables>(CheckOnboardingDocument, options);
      }
export function useCheckOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckOnboardingQuery, CheckOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckOnboardingQuery, CheckOnboardingQueryVariables>(CheckOnboardingDocument, options);
        }
export function useCheckOnboardingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckOnboardingQuery, CheckOnboardingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckOnboardingQuery, CheckOnboardingQueryVariables>(CheckOnboardingDocument, options);
        }
export type CheckOnboardingQueryHookResult = ReturnType<typeof useCheckOnboardingQuery>;
export type CheckOnboardingLazyQueryHookResult = ReturnType<typeof useCheckOnboardingLazyQuery>;
export type CheckOnboardingSuspenseQueryHookResult = ReturnType<typeof useCheckOnboardingSuspenseQuery>;
export type CheckOnboardingQueryResult = Apollo.QueryResult<CheckOnboardingQuery, CheckOnboardingQueryVariables>;
export function refetchCheckOnboardingQuery(variables: CheckOnboardingQueryVariables) {
      return { query: CheckOnboardingDocument, variables: variables }
    }