import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelfLogisticStoreStatusQueryVariables = Types.Exact<{
  userIds?: Types.InputMaybe<ReadonlyArray<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type SelfLogisticStoreStatusQuery = { readonly getLogisticStoreStatus?: Types.LogisticFeatureFlag | null };


export const SelfLogisticStoreStatusDocument = gql`
    query SelfLogisticStoreStatus($userIds: [ID!]) {
  getLogisticStoreStatus(userIds: $userIds)
}
    `;

/**
 * __useSelfLogisticStoreStatusQuery__
 *
 * To run a query within a React component, call `useSelfLogisticStoreStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfLogisticStoreStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfLogisticStoreStatusQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSelfLogisticStoreStatusQuery(baseOptions?: Apollo.QueryHookOptions<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>(SelfLogisticStoreStatusDocument, options);
      }
export function useSelfLogisticStoreStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>(SelfLogisticStoreStatusDocument, options);
        }
export function useSelfLogisticStoreStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>(SelfLogisticStoreStatusDocument, options);
        }
export type SelfLogisticStoreStatusQueryHookResult = ReturnType<typeof useSelfLogisticStoreStatusQuery>;
export type SelfLogisticStoreStatusLazyQueryHookResult = ReturnType<typeof useSelfLogisticStoreStatusLazyQuery>;
export type SelfLogisticStoreStatusSuspenseQueryHookResult = ReturnType<typeof useSelfLogisticStoreStatusSuspenseQuery>;
export type SelfLogisticStoreStatusQueryResult = Apollo.QueryResult<SelfLogisticStoreStatusQuery, SelfLogisticStoreStatusQueryVariables>;
export function refetchSelfLogisticStoreStatusQuery(variables?: SelfLogisticStoreStatusQueryVariables) {
      return { query: SelfLogisticStoreStatusDocument, variables: variables }
    }